.fixed-center-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	max-width: 50%;
	width: 100%;
}

@media screen and (max-width: 767px) {
	.fixed-center-container {
		max-width: 90%;
	}
}
