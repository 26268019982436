.app-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: hidden;
}

.app-content-container {
	flex: 1;
	height: 100%;
	background-color: #fbfbfb;
}

.app-content-inner-container {
	width: 1000px;
	padding: 0;
}

@media screen and (max-width: 1200px) {
	.app-content-inner-container {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.app-content-container {
		width: 100%;
		height: calc(100% - 75px);
	}
}

.main-content-container {
	height: calc(100vh - 85px);
	margin: 20px 0;
	border-radius: 10px;
}

.main-content-inner-container {
	height: calc(100vh - 90px);
	overflow: hidden auto;
	background-color: #ffffff;
	border: 1px solid #f1f3f4;
	border-radius: 10px;
	box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.grid-tool-left-container {
	position: relative;
	width: 450px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* align-items: center; */
	border-right: 1px solid var(--default-border-color);
	transition: var(--default-transition);
}

.grid-tool-left-container .company-name {
	font-size: 20px;
	width: 440px;
	margin-bottom: 5px;
}

.grid-tool-left-container .created-date {
	font-size: 14px;
}

.grid-tool-content-container {
	flex: 1;
	height: 100%;
	background-color: #fbfbfb;
}

.grid-tool-content-inner-container {
	width: 1000px;
	padding: 0;
}

.grid-tool-content-inner-container .area-radius-value {
	width: 100px;
}

.grid-tool-buttons-container {
	padding: 15px;
}

.grid-tool-content-container .map-wrapper.no-transition .ranking-bubble,
.grid-tool-content-container .map-wrapper.no-transition .ranking-bubble .details {
	transition: none !important;
	animation: none !important;
}

@media screen and (max-width: 1200px) {
	.grid-tool-content-inner-container {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.grid-tool-content-container {
		width: 100%;
		height: calc(100% - 75px);
	}
}

/* accordion */
.api-methods-accordion {
	height: calc(100vh - 95px);
	overflow-y: auto;
	border-radius: 5px;
}

.accordion-item {
	border: 0 !important;
	border-top: 1px solid #dee2e6 !important;
}

.accordion-item:first-of-type {
	border-top: 0 !important;
}

.accordion-header .accordion-button {
	background-color: #ffffff;
	color: #212529 !important;
	padding: 0.75rem 1rem 0.5rem 1rem;
	font-size: 15px;
	font-weight: bold;
}

.accordion-header .accordion-button:focus,
.accordion-header .accordion-button:not(.collapsed) {
	box-shadow: none;
}

.accordion-body {
	padding: 0 0 0.5rem 0;
}

.accordion-body .btn-api-method {
	font-size: 15px;
	color: #212529 !important;
	padding: 3px 20px;
	border-radius: 0;
}

.accordion-body .btn-api-method:last-child {
	margin-bottom: 0;
}

.accordion-body .btn-primary.btn-api-method {
	font-weight: bold;
	color: #ffffff !important;
	border-radius: 0;
	/* padding: 6px 20px 3px 20px; */
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.json-viewer {
	display: block;
	height: 400px;
	overflow: auto;
}

.json-viewer code {
	display: block;
	overflow-y: auto;
	text-wrap: auto;
}

.react-json-view .icon-container .expanded-icon,
.react-json-view .icon-container .collapsed-icon,
.react-json-view .copy-icon {
	position: relative;
	top: 3px;
}

.react-json-view .variable-row,
.react-json-view .object-key-val {
	padding-top: 1px !important;
	padding-bottom: 1px !important;
}
