.campaign-v-p-report-v3 {
	text-align: center;
}

.campaign-v-p-report-v3 .report-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	margin-bottom: 10px;
	background-color: white;
	padding: 10px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 3;
}

.campaign-v-p-report-v3 .report-header .display-5 {
	font-size: 2rem;
	font-weight: 500;
	font-size: 20px;
}

.campaign-v-p-report-v3 .report-header .text-muted {
	font-size: 16px;
}

.campaign-v-p-report-v3 .report-header .report-header-right {
	text-align: right;
}

.campaign-v-p-report-v3 .rank-report .rank-table thead {
	top: 142px;
}

.campaign-v-p-report-v3 .report-body {
	text-align: left;
}

.campaign-v-p-report-v3 .report-body .grid-report-keywords {
	top: 106px;
}

.campaign-v-p-report-v3 .report-body .spinner-border {
	display: block;
	margin: 0 auto;
}

.campaign-v-p-report-v3 .ranking-filters {
	margin-top: 30px;
}

.campaign-v-p-report-v3 .campaign-reports {
	position: sticky;
	top: 44px;
	z-index: 3;
	background-color: white;
	margin-bottom: 5px;
}

.campaign-v-p-report-v3 .campaign-reports .flex-fill {
	text-align: left;
}

.updated-version-notice {
	font-size: 18px;
}

@media screen and (max-width: 767px) {
	.campaign-v-p-report-v3 .report-header {
		flex-direction: column;
		align-items: stretch;
	}

	.campaign-v-p-report-v3 .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report-v3 .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report-v3 .rank-report .rank-table thead {
		top: 159px;
	}
}

@media screen and (max-width: 991px) {
	.campaign-v-p-report-v3 .report-header {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 0;
	}

	.campaign-v-p-report-v3 .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report-v3 .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report-v3 .rank-report .rank-table thead {
		top: 159px;
	}

	.campaign-v-p-report-v3 .campaign-reports {
		top: 58px;
	}

	.campaign-v-p-report-v3 .report-body .grid-report-keywords {
		top: 190px;
	}
}
