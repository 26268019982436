.gbp-profile-card {
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	border: 4px solid var(--primary-border-color);
	border-radius: 20px;
	overflow: hidden;
	text-align: left;
	container-type: inline-size;
	container-name: gbpCard;
}

.gbp-profile-card .gbp-map {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	border: 1px solid #ddd;
	border-radius: 10px;
	overflow: hidden;
}

.gbp-profile-card .gbp-map iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0 !important;
}

.gbp-profile-card .business-name {
	line-height: 1.4em;
}

.gbp-profile-card .business-address {
	font-size: .7em;
	line-height: 1.5em;
}

.gbp-profile-card .business-actions {
	margin-top: 20px;
}

.gbp-profile-card .business-actions .btn {
	margin-right: 10px;
}

.gbp-profile-card .business-actions .btn:last-child {
	margin-right: 0;
}

.gbp-profile-card .business-stats {
	margin-top: calc(var(--gap) / 2);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: var(--gap);
}

.gbp-profile-card .business-stats .business-stat {
	text-align: center;
}

.gbp-profile-card .business-stats .business-stat .icon {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
	padding: 10px;
	border-radius: 10px;
	background-color: #f2f2f2;
	color: #41464b;
}

.gbp-profile-card .business-stats .business-stat .icon.rating i {
	color: gold;
}

.gbp-profile-card .business-stats .business-stat .label {
	margin-top: 6px;
	font-size: .8em;
}

@container gbpCard (max-width: 370px) {
	.gbp-profile-card .business-actions .btn span {
		display: none;
	}

	.gbp-profile-card .business-actions .btn i {
		margin: 0;
	}
}
