.campaign-v-p-report-summary {
	text-align: center;
	padding: 12px;
}

.campaign-v-p-report-summary .report-header-container {
	position: sticky;
	top: 0;
	left: 0;
	padding-bottom: 1px;
	background-color: #ffffff;
	z-index: 98;
}

.campaign-v-p-report-summary .report-keyword-container {
	margin-bottom: 10px;
	background-color: #f9f9f9;
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 15px;
}

.campaign-v-p-report-summary .report-header .display-5 {
	font-size: 2rem;
	font-weight: 500;
	font-size: 20px;
}

.campaign-v-p-report-summary .report-header .text-muted {
	font-size: 16px;
}

.campaign-v-p-report-summary .report-header .report-header-right {
	text-align: right;
}

.campaign-v-p-report-summary .nav .nav-item {
	margin: 3px 5px 3px 0;
	border: 1px solid #ced4da;
	border-radius: 5px;
}

.campaign-v-p-report-summary .rank-report .rank-table thead {
	top: 160px;
}

.campaign-v-p-report-summary .report-body {
	text-align: left;
}

.campaign-v-p-report-summary .report-body .grid-report-keywords {
	top: 106px;
}

.campaign-v-p-report-summary .report-body .spinner-border {
	display: block;
	margin: 20px auto;
}

.campaign-v-p-report-summary .ranking-filters {
	margin-top: 30px;
}

.campaign-v-p-report-summary .campaign-reports {
	background-color: #f9f9f9;
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 15px;
}

.campaign-v-p-report-summary .canceled-campaign-warning {
	padding: 40px;
	font-size: 28px;
	font-weight: bold;
	color: #e91717;
}

.campaign-v-p-report-summary .no-reports-notice {
	padding: 20px;
	font-size: 20px;
}

.campaign-v-p-report-summary .campaign-reports .flex-fill {
	text-align: left;
}

.campaign-v-p-report-summary .report-headline-links-wrapper {
	margin: 3px 0;
	text-align: end;
}

.campaign-v-p-report-summary .report-headline-links-wrapper .report-headline-link {
	font-size: 18px;
	background-color: #dbdbdb;
	color: #505050;
	border-radius: 5px;
	margin-right: 8px;
	padding: 3px 8px;
	min-width: 35px;
}

.campaign-v-p-report-summary .report-headline-links-wrapper .report-headline-link:last-child {
	margin-right: 0;
}

.campaign-v-p-report-summary .report-headline-links-wrapper .report-headline-link i {
	margin-right: 0;
}

.report-headline-link-separator {
	font-size: 26px;
	vertical-align: middle;
	padding: 0 6px 0 0;
}

.campaign-v-p-report-summary .one-line-input-label {
	min-width: 150px;
}

.campaign-v-p-report-summary .keyword-map-stats-v4 .keyword-stat-item {
	background-color: #ffffff;
	border: 1px solid #ced4da;
	border-radius: 5px;
	max-height: 76px;
}

.campaign-v-p-report-summary .map-wrapper .map {
	border: 1px solid #ced4da;
	border-radius: 5px;
}

.campaign-v-p-report-summary .ranking-bubble {
	border-radius: 3px;
}

.campaign-v-p-report-summary .ranking-bubble .icon {
	border-radius: 3px;
}

.campaign-v-p-report-summary .ranking-bubble .icon .value {
	border-radius: 3px;
	font-size: 15px;
	font-weight: 500;
}

.campaign-v-p-report-summary .ranking-bubble.excellent .icon .value {
	background-color: #dfffe3;
	color: #40ae4e;
	border: 1px solid #40ae4e;
}

.campaign-v-p-report-summary .ranking-bubble.decent .icon .value {
	background-color: #ffcfb0;
	color: #fd8331;
	border: 1px solid #fd8331;
}

.campaign-v-p-report-summary .ranking-bubble.bad .icon .value {
	background-color: #fdbaba;
	color: #ff3d3d;
	border: 1px solid #ff3d3d;
}

.campaign-v-p-report-summary .ranking-bubble.low .icon .value {
	background-color: #f0f0f0;
	color: #a5a5a5;
	border: 1px solid #a5a5a5;
}

@media screen and (max-width: 767px) {
	.campaign-v-p-report-summary .report-header {
		flex-direction: column;
		align-items: stretch;
	}

	.campaign-v-p-report-summary .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report-summary .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report-summary .rank-report .rank-table thead {
		top: 159px;
	}

	.campaign-v-p-report-summary .ranking-bubble {
		border-radius: 2px;
	}

	.campaign-v-p-report-summary .ranking-bubble .icon {
		min-width: 20px;
		min-height: 20px;
		border-radius: 2px;
	}

	.campaign-v-p-report-summary .ranking-bubble .icon .value {
		line-height: 18px;
		font-size: 10px;
		border-radius: 2px;
	}
}

@media screen and (max-width: 991px) {
	.campaign-v-p-report-summary .report-header {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 0;
	}

	.campaign-v-p-report-summary .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report-summary .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report-summary .rank-report .rank-table thead {
		top: 110px;
	}

	.campaign-v-p-report-summary .campaign-reports {
		top: 125px;
	}

	.campaign-v-p-report-summary .report-body .grid-report-keywords {
		top: 190px;
	}
}
