.header-account-menu {
	position: absolute;
	right: 0;
	top: 44px;
	width: 25vw;
	padding: 20px;
	padding-bottom: 20px;
	text-transform: capitalize;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0 0 1rem rgba(0, 0, 0, .15);
	z-index: 98;
}

.header-account-menu .user-profile {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-transform: capitalize;
}

.header-account-menu .user-profile .profile-pic img {
	max-width: 100px;
	border-radius: 50%;
	overflow: hidden;
}

.header-account-menu .btn {
	min-height: auto;
	padding-left: 0;
	transition: all .3s ease-out;
}

.header-account-menu .btn:hover {
	text-decoration: underline;
}

.credits-info {
	padding: 5px 20px;
	margin-bottom: 10px;
	background-color: #ececec;
	border: 1px solid #d8d8d8;
	font-weight: bold;
}

.credits-info.warning {
	color: #e03f3f;
}

@media screen and (max-width: 767px) {
	.header-account-menu {
		width: calc(100vw - 20px);
	}
}
