.campaign-selector {
	padding: 10px 10px;
	background-color: white;
	border-bottom: 1px solid #f2f2f2;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}
