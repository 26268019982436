.grid-report-keywords {
	position: sticky;
	z-index: 3;
	top: 76px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 30px;
	padding: 10px 0 10px 0;
	margin: 0;
	background: white;
}

.grid-report-keywords .grid-report-keyword {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 20px;
	background-color: #ddd;
	border: 1px solid #f2f2f2;
	border-radius: 5px;
	cursor: pointer;
}

.grid-report-keywords .grid-report-keyword.active {
	color: var(--primary-color);
	border-color: var(--primary-color);
}

@media screen and (max-width: 767px) {
	.grid-report-keywords {
		top: 91px;
	}
}
