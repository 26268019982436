@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

:root {
	--gap: 30px;
	--default-margin: 30px;
	--default-border-color: #f1f1f1;
	--primary-color: #1444f5;
	--primary-border-color: #ced9fc;
	--modal-dialog-background-color: white;
	--modal-buttons-shaded-color: #f5f6fa;
	--modal-buttons-shaded-border-color: #ddd;
}

body {
	font-family: 'Inter', sans-serif;
}

body.body-full-screen {
	overflow: hidden;
}

body.gray-background {
	background-color: #f6f6f6;
}

* {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

.app {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.btn.btn-link {
	text-decoration: none;
}

.btn i {
	margin-right: 5px;
}

.buttons-container {
	margin-top: var(--gap);
}

.buttons-container .btn {
	margin-right: 10px;
}

.buttons-container .btn:last-child {
	margin-right: 0;
}

.app-link-button {
	color: #212529;
	text-decoration: underline !important;
	padding: 0;
}

.full-screen {
	position: fixed;
	top: 68px;
	left: 0;
	bottom: 0;
	right: 0;
	/* width: 100%; */
	height: calc(100% - 68px);
	z-index: 999;
	background-color: white;
}

.form-label {
	margin-bottom: 0.25rem;
}

.nav-tabs .nav-link {
	color: #495057;
	background-color: #f1f1f1;
	border: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active {
	border-bottom: 1px solid #dee2e6;
}

.tab-content {
	border-top: 0 !important;
}

table.items-list {
	margin-bottom: 0;
}

table.items-list thead {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: white;
}

table.items-list tfoot {
	position: sticky;
	bottom: -1px;
	z-index: 1;
	background-color: white;
}

table.items-list tfoot th {
	font-weight: normal;
}

table.items-list tr:last-child th,
table.items-list tr:last-child td,
table.items-list tfoot tr:last-child th,
table.items-list tfoot tr:last-child td {
	border-bottom: 0;
}

table.items-list td {
	font-size: 16px;
}

table.items-list th.date,
table.items-list td.date,
table.items-list th.status,
table.items-list td.status {
	width: 180px;
	text-align: center;
}

table.items-list td .btn {
	color: #414141;
	padding: 5px 10px;
}

table.items-list td .btn i {
	font-size: 20px;
}

.search-list-wrapper {
	display: flex;
	position: relative;
	width: 300px;
	text-align: right;
}

.search-list-wrapper input {
	padding-left: 30px;
	border-radius: 5px;
}

.search-list-wrapper .btn-search {
	position: absolute;
	left: 0;
	color: #585858;
}

.search-list-wrapper input.has-value,
.table-search-filter input.has-value {
	background-color: #f8dede;
	border: 1px solid #ff0000;
	font-weight: bold;
}

.shadow-large {
	box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.loading-map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(255 255 255 / 65%);
	display: flex;
	justify-content: center;
	align-items: center;
}

/* time picker */
.MuiInputBase-input {
	padding: 8px !important;
	color: #212529 !important;
}

#chat-widget-container {
	z-index: 999 !important;
	visibility: visible !important;
	opacity: 1 !important;
	margin-right: 10px;
}
