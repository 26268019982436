table.landing-pages-table {
	table-layout: fixed;
}

table.landing-pages-table .keyword {
	width: 20%;
}

table.landing-pages-table .headline-prompt,
table.landing-pages-table .content-prompt {
	width: 35%;
}

table.landing-pages-table .keyword-action {
	width: 10%;
}

table.landing-pages-table td .btn {
	color: #414141;
	padding: 5px 10px;
}

table.landing-pages-table td .btn i {
	font-size: 20px;
}
