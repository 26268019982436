.campaign-v-p-report-v5 .mobile-view-buttons {
	margin: 0;
}

.campaign-v-p-report-v5 .report-toggle-button {
	background-color: transparent;
	color: #212529;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 15px 20px;
	width: 50%;
}

.campaign-v-p-report-v5 .report-toggle-button.active {
	background-color: #0d6efd;
	color: #ffffff;
	border: 1px solid #094eb4;
	font-weight: normal;
}
