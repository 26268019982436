.report-settings {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
}

.report-settings .sidebar {
	min-width: 20%;
	padding: 10px;
	border-right: 1px solid #f2f2f2;
}

.report-settings .content {
	flex: 1;
	padding: 10px 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
}

.report-settings .content > * {
	flex: 1;
	padding-right: var(--gap);
}

.report-actions {
	position: sticky;
	bottom: 0;
	width: 100%;
	padding: 10px 10px;
	background-color: white;
	border-top: 1px solid #f2f2f2;
}
