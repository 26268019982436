.profile-info-container {
	text-align: right;
}

.profile-info {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin: 0 0 0 auto;
	text-align: left;
	text-transform: capitalize;
	position: relative;
}

.profile-info .profile-pic {
	overflow: hidden;
	flex: 1;
}

.profile-info .profile-pic img {
	display: block;
	border-radius: 50%;
	max-width: 50px;
	overflow: hidden;
}

.profile-info .profile-name {
	text-align: left;
}

.profile-info .name {
	font-size: 18px;
	white-space: nowrap;
}

.profile-info .email {
	display: none;
	font-size: 12px;
	color: #c1c1c1;
}

.profile-info .btn-profile-menu {
	margin-left: 10px;
	display: block;
	width: 35px;
	line-height: 35px;
	background-color: var(--default-border-color);
	color: var(--text-color);
	border-radius: 50%;
	text-align: center;
	font-size: 22px;
	transition: all .3s ease-out;
}

.profile-info .btn-profile-menu:hover {
	background-color: #dddddd;
	/* color: #007bff; */
}

.profile-info .btn-profile-menu.active {
	background-color: #dddddd;
	/* color: #007bff; */
}
