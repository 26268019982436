.mobile-view-buttons {
	margin: 10px 0 15px;
}

.report-toggle-button {
	background-color: white;
	color: #212529;
	border: 0;
	padding: 5px 20px;
	width: 50%;
}

.report-toggle-button.active {
	border-bottom: 1px solid #ccc;
	font-weight: bold;
}
