.keyword-map-stats-v4 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
}

.keyword-map-stats-v4 .full-width {
	flex: 1;
}

.keyword-map-stats-v4 .half-width {
	flex: 1;
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 10px;
}

.keyword-map-stats-v4 .keyword-stat-item {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	background-color: #f9f9f9;
	color: black;
	padding: 10px 8px 10px 12px;
	border: 1px solid transparent;
	border-radius: 10px;
}

.keyword-map-stats-v4 .keyword-stat-item.selectable {
	cursor: pointer;
}

.keyword-map-stats-v4 .keyword-stat-item .good {
	color: #1ac049;
}

.keyword-map-stats-v4 .keyword-stat-item .bad {
	color: #ff2b2b;
}

.keyword-map-stats-v4 .keyword-stat-item .label {
	color: #58687c;
}

.keyword-map-stats-v4 .keyword-stat-item .value-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
}

.keyword-map-stats-v4 .keyword-stat-item .value {
	font-weight: 600;
	font-size: 20px;
}

.keyword-map-stats-v4 .keyword-stat-item .change {
	font-weight: 400;
	font-size: 14px;
}

.keyword-map-stats-v4 .keyword-stat-item .change.average {
	min-width: 70px;
	text-align: right;
}

.keyword-map-stats-v4 .keyword-stat-item.selected {
	background-color: #ededed;
	border: 1px solid #d3d3d3;
}

.keyword-map-stats-v4 .keyword-stat-item .stat-data.selected {
	position: absolute;
	bottom: 0px;
	right: 5px;
	color: #6b6b6b;
}

@media screen and (max-width: 767px) {
	.keyword-map-stats-v4 {
		flex-direction: row;
		align-items: stretch;
	}
}
