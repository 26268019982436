.spinner-wrapper {
	text-align: center;
	margin-top: 12px;
}

.btn .spinner-border {
	width: 18px;
	max-height: 18px;
}

.btn.btn-lg .spinner-border {
	width: 24px;
	max-height: 24px;
}
