.radio-image-type-wrapper {
	padding: 0;
	margin-top: 10px;
}

.radio-image-type-wrapper label {
	margin-right: 25px;
}

.radio-image-type-wrapper input[type=radio] {
	margin-right: 5px;
}
