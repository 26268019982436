.campaign-p-report {
	text-align: center;
}

.campaign-p-report .report-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	margin-bottom: var(--gap);
	background-color: #f2f2f2;
	padding: 10px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
}

.campaign-p-report .report-header .display-5 {
	font-size: 2rem;
}

.campaign-p-report .report-header .report-header-right {
	text-align: right;
}

.campaign-p-report .rank-report .rank-table thead {
	top: 82px;
}

.campaign-p-report .report-body {
	text-align: left;
}
