.report-info-container {
	padding: 20px;
	font-family: Poppins, sans-serif;
	color: #444444;
}

.report-info-container .container {
	padding: 0 20px;
}

.report-info-container .report-info-container-inner {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 40px;
	/* height: calc(100vh - 30px); */
	box-shadow: 0px 0px 7px 0px #dddddd;
}

.report-info-container .report-info-container-inner .campaign-title-wrapper {
	padding-bottom: 40px;
}

.report-info-container .report-info-container-inner .campaign-title {
	font-weight: 700;
	font-size: 32px;
	color: #0b305b;
}

.report-info-container .report-info-container-inner p {
	font-size: 20px;
	line-height: 1.4em;
}

.report-info-container .report-info-container-inner a {
	color: #2b7bb9;
}

.report-info-container .report-info-container-inner pre {
	font-family: Poppins, sans-serif;
	color: #444444;
	font-size: 20px;
	line-height: 1.4em;
	text-wrap: wrap;
}

.report-info-container .report-info-container-inner .video-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
}

.report-info-container .report-info-container-inner .video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.report-info-container .report-info-container-inner .form-label {
	min-width: 150px;
}

.report-info-container .report-info-container-inner a.report-url-link {
	background-color: #4ab24a;
	border-color: #327a39;
	border: 1px solid #1f5a87;
	border-radius: 4px;
	width: 100%;
	padding: 10px 20px;
	font-size: 25px;
	color: #ffffff;
}

@media screen and (min-width: 992px) {
	.report-info-container .container {
		width: 800px;
	}
}

@media screen and (max-width: 768px) {
	.report-info-container {
		padding: 10px;
		padding-bottom: 80px;
	}

	.report-info-container .container {
		padding: 0;
	}

	.report-info-container .report-info-container-inner {
		padding: 20px;
	}

	.report-info-container .report-info-container-inner .campaign-title-wrapper {
		padding-bottom: 30px;
	}
}
