.campaign-v-p-report {
	text-align: center;
}

.campaign-v-p-report .report-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	margin-bottom: 10px;
	background-color: white;
	padding: 10px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 3;
}

.campaign-v-p-report .report-header .display-5 {
	font-size: 2rem;
	font-weight: 500;
	font-size: 28px;
}

.campaign-v-p-report .report-header .text-muted {
	font-size: 16px;
}

.campaign-v-p-report .report-header .report-header-right {
	text-align: right;
}

.campaign-v-p-report .rank-report .rank-table thead {
	top: 142px;
}

.campaign-v-p-report .report-body {
	text-align: left;
}

.campaign-v-p-report .ranking-filters {
	margin-top: 30px;
}

.campaign-v-p-report .campaign-reports .flex-fill {
	text-align: left;
}

@media screen and (max-width: 767px) {
	.campaign-v-p-report .report-header {
		flex-direction: column;
		align-items: stretch;
	}

	.campaign-v-p-report .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report .rank-report .rank-table thead {
		top: 159px;
	}
}
