.users_select__menu,
.products_select__menu {
	z-index: 3 !important;
}

/* .products_select__menu-list {
	padding: 0 !important;
}

.products_select__single-value {
	height: 24px;
}

.products_select__option {
	padding: 0 12px !important;
	border-bottom: 1px solid #dddddd;
} */

/* .offer-item-container.offer-item-header {
	padding-top: calc(var(--default-margin) / 3);
} */

.offer-item-container .offer-item-details {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* border-bottom: 1px solid var(--default-border-color); */
	/* padding-left: calc(var(--default-margin) / 2); */
	/* padding-right: calc(var(--default-margin) / 2); */
	padding-top: calc(var(--default-margin) / 3);
	padding-bottom: calc(var(--default-margin) / 3);
}

.offer-item-container .offer-item-details .offer-item {
	flex: 1;
	padding-right: 0;
}

.offer-item-container .offer-item-details .offer-item-amount {
	flex: 1;
	padding-left: calc(var(--default-margin) / 2);
}

.offer-item-container .offer-item-details .offer-item-amount input {
	text-align: right;
}

.offer-item-container .offer-item-details .offer-item-days {
	flex: 0.5;
	padding-left: calc(var(--default-margin) / 2);
}

.offer-item-container .offer-item-details .offer-item-action {
	padding-left: calc(var(--default-margin) / 2);
	min-width: 55px;
}

.total-line-item {
	display: flex;
	flex-direction: row;
	/* justify-content: flex-end; */
	align-items: center;
}

.total-line-item .total-line-item-details {
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	/* padding-left: calc(var(--default-margin) / 2); */
	/* padding-right: calc(var(--default-margin) / 2); */
	padding-top: calc(var(--default-margin) / 6);
	padding-bottom: calc(var(--default-margin) / 6);
}

.total-line-item .total-line-item-details .total-line-item-label {
	min-width: 170px;
}

.total-line-item .total-line-item-details strong {
	padding-right: var(--default-margin);
}

.total-line-item .total-line-item-details input {
	text-align: right;
}

.subscription-details .presets-container {
	background-color: var(--subcontainer-background-color);
	border-bottom: 1px solid var(--default-border-color);
	padding: calc(var(--default-margin) / 3) 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.subscription-details .presets-container div {
	padding: 8px 0px;
}

.subscription-details .presets-container .preset {
	padding: 8px 0px;
	cursor: pointer;
	margin-left: 20px;
	border: 1px solid #ddd;
	border-radius: var(--default-border-radius);
}

.subscription-details .container-fluid {
	padding: 10px 0px;
}

.actions-bar-container {
	padding-left: 0;
	padding-bottom: 0;
}

table.system-offers-list th.select-offer,
table.system-offers-list td.select-offer {
	width: 120px;
	text-align: center;
}

table.system-offers-list td.offer-details {
	padding: 15px;
}

table.system-offers-list td.offer-details .offer-name {
	font-size: 20px;
	margin-bottom: 5px;
}
