.requirements-approval-thank-you-container {
	padding: 20px;
	font-family: Poppins, sans-serif;
	color: #444444;
}

.requirements-approval-thank-you-container .container {
	padding: 0 20px;
}

.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 40px;
	/* height: calc(100vh - 30px); */
	box-shadow: 0px 0px 7px 0px #dddddd;
}

.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner .title-wrapper {
	padding-bottom: 40px;
}

.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner .title {
	font-weight: 700;
	font-size: 32px;
	color: #0b305b;
}

.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner p {
	font-size: 20px;
	line-height: 1.5em;
}

.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner a {
	color: #2b7bb9;
	text-decoration: none;
}

.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner .form-label {
	min-width: 150px;
}

@media screen and (min-width: 992px) {
	.requirements-approval-thank-you-container .container {
		width: 800px;
	}
}

@media screen and (max-width: 768px) {
	.requirements-approval-thank-you-container {
		padding: 10px;
		padding-bottom: 80px;
	}

	.requirements-approval-thank-you-container .container {
		padding: 0;
	}

	.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner {
		padding: 20px;
	}

	.requirements-approval-thank-you-container .requirements-approval-thank-you-container-inner .title-wrapper {
		padding-bottom: 30px;
	}
}
