.map-settings {
	padding: 20px;
}

.map-settings .sidebar {
	min-width: 20%;
	padding: 10px;
	border-right: 1px solid #f2f2f2;
}

.map-settings .content {
	margin-bottom: 20px;
}

.map-settings-form .generate-seo-report {
	margin-bottom: 0;
}

.map-settings-form .point-distance {
	font-size: 18px;
}

.btn-generate-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: auto;
}

.data-grid-map-container {
	height: calc(100vh - 220px) !important;
	min-height: 400px;
	/* border: 1px solid #ddd; */
	position: relative;
}

.tab-content.grid-keywords-content,
.data-grid-map-container .map {
	height: calc(100vh - 260px) !important;
	min-height: 400px;
}
