.campaign-tabs .nav-item {
	border: 1px solid #dddddd;
	border-bottom: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: #ebebeb;
}

.campaign-tabs .nav-item .nav-link {
	color: #333333;
}

.campaign-tabs .nav-item .nav-link.active {
	border: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	height: calc(100% + 1px);
}

.campaign-tabs .nav-item.disabled {
	opacity: 0.65;
}

.campaign-tabs .nav-item.disabled .nav-link {
	pointer-events: none;
}
