#tabCampaignsContent > .tab-pane {
	height: calc(100vh - 145px);
	overflow: auto;
}

#tabJobsContent > .tab-pane,
#tabReportsContent > .tab-pane {
	height: calc(100vh - 221px);
	overflow: auto;
}

#tabNewCampaignsContent .tab-pane,
#tabContentContent > .tab-pane,
#tabPaymentsContent > .tab-pane {
	height: calc(100vh - 221px);
}

#tabNewCampaignsContent .table-wrapper,
#tabContentContent .table-wrapper,
#tabPaymentsContent .table-wrapper {
	height: calc(100vh - 221px);
	overflow: auto;
}

#tabOrdersContent > .tab-pane,
#tabApprovalOrdersContent > .tab-pane {
	height: calc(100vh - 300px);
	overflow: auto;
}

#tabOrdersContent .table-wrapper {
	height: calc(100vh - 300px);
}

#tabApprovedOrderContent > .tab-pane {
	height: calc(100vh - 175px);
	overflow: auto;
}

#tabRequirementsDetailsContent > .tab-pane {
	height: calc(100vh - 377px);
	overflow: auto;
}

.nav-tabs .nav-item {
	position: relative;
}

.nav-tabs .nav-item .notification-counter {
	position: absolute;
	top: 0px;
	right: 0;
	padding: 0 3px;
	margin-left: 5px;
	color: #ffffff;
	background-color: #e94646;
	border: 1px solid #e72b2b;
	border-radius: 5px;
	font-size: 11px;
	line-height: 14px;
	min-width: 16px;
	text-align: center;
}

.new-order-wrapper,
.mark-as-completed-wrapper {
	position: absolute;
	top: -50px;
	right: 0px;
	z-index: 2;
}

.message-wrapper {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
}

.message-wrapper:last-child {
	margin-bottom: 0px;
}

.message-wrapper.outbound {
	justify-content: flex-end;
}

.message-wrapper .message-details {
	position: relative;
	padding: 5px 10px 25px 10px;
	border-radius: 10px;
	max-width: 80%;
}

.message-wrapper.outbound .message-details {
	background-color: #daeefc;
}

.message-wrapper.inbound .message-details {
	background-color: #eeeeee;
}

.message-wrapper .message-details pre {
	font-family: inherit;
	font-size: 16px;
	padding: 3px;
	text-wrap: wrap;
}

.message-wrapper .message-details .message-date {
	position: absolute;
	padding: 0;
	font-size: 12px;
	bottom: 3px;
	right: 12px;
}

.table-search-filter {
	position: sticky;
	top: 0;
	z-index: 1;
	background: white;
}

#active-campaigns-tab-pane table thead {
	top: 71px;
}

table.campaigns-list tr:hover {
	background-color: #ececec;
}

table.campaigns-list tfoot tr:hover {
	background-color: white;
}

table.campaigns-list tr.job-running,
table.campaigns-list tr.queue-running {
	background-color: #ececec;
}

.auto-process-icon {
	position: absolute;
	top: -4px;
	right: -8px;
	font-size: 11px;
}

table.campaigns-list tr.is-today {
	background-color: #afd0b4;
}

table.campaigns-list tr.is-tomorrow {
	background-color: #fcf9d8;
}

table.campaigns-list tr.report-outdated {
	background-color: #f0d8d8;
}

table.campaigns-list th.date,
table.campaigns-list td.date {
	width: 150px;
	text-align: center;
}

table.campaigns-list th.date-time,
table.campaigns-list td.date-time,
table.campaigns-list th.status,
table.campaigns-list td.status {
	width: 200px;
	text-align: center;
}

table.campaigns-list td.error i {
	color: #ca1e1e;
}

table.campaigns-list th.setting,
table.campaigns-list td.setting {
	width: 140px;
	text-align: center;
}

table.campaigns-list th.count,
table.campaigns-list td.count {
	width: 100px;
	text-align: center;
}

table.campaigns-list th.campaign-name,
table.campaigns-list td.campaign-name {
	max-width: 400px;
}

table.campaigns-list th.url,
table.campaigns-list td.url,
table.campaigns-list td.url a {
	width: 500px;
}

table.campaigns-list td.task-action {
	width: 210px;
}

table.campaigns-list td.task-action .btn-link {
	padding-left: 5px;
	padding-right: 5px;
}

table.campaigns-list td .action-button-placeholder {
	display: inline-block;
	width: 43px;
}

.one-line-input-label {
	min-width: 150px;
}

.is-filtering-table {
	filter: blur(2px);
	opacity: 0.7;
}

.setting-checkbox.disabled {
	pointer-events: none;
}

table.campaigns-list td .merged-notice {
	background-color: #ffc107;
	border-radius: 5px;
	margin-left: 5px;
	padding: 0 5px;
	font-size: 14px;
}

table.campaigns-list td .merged-notice i {
	color: #525252;
	font-size: 13px;
}
