.canned-comment-item .form-check:hover {
	background-color: #ececec;
}

.canned-comment-item .comment-label {
	width: calc(100% - 40px);
}

.canned-comment-tag {
	font-size: 14px;
	margin-right: 8px;
	padding: 3px 8px;
	border: 1px solid #e7e7e7;
}

.canned-comment-tag.tag-small {
	display: inline-block;
	position: relative;
	top: -2px;
	font-size: 12px;
	margin-left: 5px;
	padding: 0px 4px;
	border: 1px solid #e7e7e7;
	border-radius: 4px;
}

.canned-comment-tag.tag-small:first-child {
	margin-left: 10px;
}

.canned-comment-action-btn {
	position: relative;
	top: -2px;
	margin-left: 10px;
	font-size: 16px;
}

.canned-comment-tag.btn-light {
	background-color: #e7e7e7;
	border: 1px solid #dbdbdb;
}

.btn-canned-comments-settings {
	position: unset;
	margin-bottom: 3px;
	padding: 2px 5px;
	color: #585858;
}

.canned-comments-label {
	background-color: #faf5b2;
	border: 1px solid #e7e7e7;
	border-radius: 5px;
	padding: 1px 10px;
	margin-right: 5px;
}
