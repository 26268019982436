.page-header-container {
	display: flex;
	padding-bottom: var(--gap);
	justify-content: flex-start;
	align-items: center;
}

.headline-wrapper {
	flex: 1 1 auto;
}

.page-header-container > .btn {
	margin-right: 20px;
}

.page-header-container > .btn i {
	margin-right: 10px;
}

.page-header-container .page-headline-links-wrapper {
	margin-left: 30px;
}

.page-header-container .page-headline-links-wrapper .page-headline-link {
	font-size: 20px;
	color: #333333;
}

.logged-in-wrapper {
	display: flex;
	align-items: center;
	border: 1px solid #ff4f4f;
	border-radius: 7px;
	background-color: #ff7878;
	padding: 3px 20px;
	margin-right: 30px;
	font-size: 18px;
	font-weight: bold;
}

.logged-in-wrapper .btn {
	color: #212529;
	font-size: 18px;
	font-weight: bold;
}
