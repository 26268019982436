.report-selector {
	margin-bottom: 10px; /* var(--gap); */
}

.report-selector .reports {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: var(--gap);
	margin-bottom: calc(var(--gap) / 2);
	margin-top: calc(var(--gap) / 2);
}

.report-selector .reports .campaign-report {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 20px;
	background-color: #ddd;
	border: 1px solid #f2f2f2;
	border-radius: 5px;
	cursor: pointer;
}

.report-selector .reports .campaign-report.active {
	color: var(--primary-color);
	border-color: var(--primary-color);
}
