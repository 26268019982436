.campaign-buttons-container {
	position: sticky;
	display: flex;
	bottom: 0;
	width: 100%;
	padding: 10px 20px;
	border: 1px solid #dddddd;
	background-color: #f7f7f7;
	text-align: end;
}
