.settings-dialog-wrapper .modal-dialog-container .modal-dialog {
	width: 800px;
}

#tabSettingsContent .tab-pane {
	height: calc(100vh - 270px);
}

#tabSettingsContent #prompts-tab-pane {
	overflow: auto;
}

#tabSettingsContent .table-wrapper {
	height: calc(100vh - 270px);
	overflow: auto;
}

#tabWebsitesContent .tab-pane {
	height: calc(100vh - 280px);
}

#tabWebsitesContent .table-wrapper {
	height: calc(100vh - 280px);
	overflow: auto;
}

.settings-dialog-wrapper .new-item-wrapper {
	position: absolute;
	top: -45px;
	right: 0px;
	z-index: 2;
}
