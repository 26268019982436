.modal-dialog-container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.modal-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .6);
	z-index: 98;
}

.modal-dialog-container .modal-dialog {
	margin: 0;
	position: relative;
	max-width: 80%;
	width: 600px;
	background-color: var(--modal-dialog-background-color);
	border-radius: 5px;
	pointer-events: initial;
}

.modal-dialog-container.full-screen > .modal-dialog {
	margin: 0;
	position: relative;
	max-width: 100%;
	width: 100%;
	height: 100vh;
	background-color: var(--modal-dialog-background-color);
	border-radius: 5px;
	pointer-events: initial;
	overflow: hidden;
}

.modal-dialog-container:not(.right-sidebar) .modal-dialog {
	max-height: 100vh;
	overflow-y: auto;
}

.modal-dialog-container.full-screen > .modal-dialog > .modal-dialog-content {
	height: calc(100% - 70px);
	overflow-y: auto;
	position: relative;
}

.modal-dialog-container.right-sidebar .modal-dialog {
	position: fixed;
	right: 0;
	margin: auto;
	width: 600px;
	max-width: 80%;
	height: 100%;
	border-radius: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.modal-dialog-container.right-sidebar.large .modal-dialog {
	width: 1000px;
}

.modal-dialog-container.right-sidebar .modal-dialog-content {
	height: calc(100vh - 70px);
	overflow-y: auto;
}

.modal-dialog-container.right-sidebar .modal-content {
	height: 100%;
	overflow-y: auto;
}

.modal-dialog-container.right-sidebar .modal-body {
	padding: 15px 15px 80px;
}

.modal-dialog-container.right-sidebar .modal-dialog-buttons {
	position: absolute;
	bottom: 0;
	width: 100%;
	border: 1px solid #dddddd;
	background-color: #f7f7f7;
}

@media screen and (max-width: 767px) {
	.modal-dialog-container .modal-dialog {
		max-width: 90%;
	}
}
