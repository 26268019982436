#tabAdministrationContent > .tab-pane {
	height: calc(100vh - 145px);
	overflow: auto;
}

#tabUsersContent .tab-pane {
	height: calc(100vh - 221px);
}

#tabUsersContent .table-wrapper {
	height: calc(100vh - 221px);
	overflow: auto;
}
