.campaign-main-container {
	padding: 20px;
}

.campaign-form-container {
	position: relative;
	border: 1px solid #dddddd;
	border-top: 0;
	border-bottom: 0;
	padding: 20px;
	height: calc(100vh - 217px);
	text-align: start;
	overflow: auto;
}

.campaign-form-container.no-overflow {
	overflow: hidden;
}

.campaign-subform-container {
	position: relative;
	border: 1px solid #dddddd;
	border-top: 0;
	padding: 20px;
	height: calc(100vh - 300px);
	text-align: start;
	overflow: auto;
}

.campaign-form-container .row {
	align-items: center;
}

/* .campaign-form-container .row .form-label {
	width: 180px;
} */

.color-output-control {
	width: 100px;
}

.campaign-form-container .row .form-label {
	margin-top: 5px;
	align-self: start;
}

.generate-ai-desc-button {
	height: 38px;
}

span.link-separator {
	position: relative;
	top: 2px;
}

.campaign-form-container .form-check-label {
	width: 250px;
}

.campaign-form-container .task-done {
	color: rgb(36, 158, 107);
}

.campaign-form-container .form-controls-additional .btn-link,
.campaign-form-container .form-controls-additional span.link-separator {
	font-size: 15px;
}
