.invoice-item-container .invoice-item-details {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* border-bottom: 1px solid var(--default-border-color); */
	/* padding-left: calc(var(--default-margin) / 2);
	padding-right: calc(var(--default-margin) / 2); */
	padding-top: calc(var(--default-margin) / 3);
	padding-bottom: calc(var(--default-margin) / 3);
}

.invoice-item-container .invoice-item-details:first-child {
	padding-top: 0;
}

.invoice-item-container .invoice-item-details .invoice-item {
	flex: 1;
	padding-right: 0;
}

.invoice-item-container .invoice-item-details .invoice-item-amount {
	flex: 1;
	padding-left: calc(var(--default-margin) / 2);
}

.invoice-item-container .invoice-item-details .invoice-item-amount input {
	text-align: right;
}

.invoice-item-container .invoice-item-details .invoice-item-days {
	flex: 0.5;
	padding-left: calc(var(--default-margin) / 2);
}

.invoice-item-container .invoice-item-details .invoice-item-action {
	padding-left: calc(var(--default-margin) / 2);
}
