:root {
	--bad-color: #ff4a4a;
	--good-color: #bdf2d5;
	--decent-color: #ff9551;
	--excellent-color: #3ccf4e;
}

.map {
	height: 800px;
}

.data-grid-report .grid-report-map {
	margin-bottom: 10px;
}
