.keyword-map-stats-v3 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
}

.keyword-map-stats-v3 .full-width {
	flex: 1;
}

.keyword-map-stats-v3 .half-width {
	flex: 1;
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 10px;
}

.keyword-map-stats-v3 .keyword-stat-item {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #f9f9f9;
	color: black;
	padding: 10px 20px;
	border-radius: 10px;
}

.keyword-map-stats-v3 .keyword-stat-item .good {
	color: #1ac049;
}

.keyword-map-stats-v3 .keyword-stat-item .bad {
	color: #ff2b2b;
}

.keyword-map-stats-v3 .keyword-stat-item .label {
	color: #58687c;
}

.keyword-map-stats-v3 .keyword-stat-item .value-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
}

.keyword-map-stats-v3 .keyword-stat-item .value {
	font-weight: 600;
	font-size: 24px;
}

.keyword-map-stats-v3 .keyword-stat-item .change {
	font-weight: 400;
	font-size: 20px;
}

@media screen and (max-width: 767px) {
	.keyword-map-stats-v3 {
		flex-direction: row;
		align-items: stretch;
	}
}
