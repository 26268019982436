#tabCompanies {
	border-bottom: 0;
}

#tabCompaniesContent .tab-pane {
	height: calc(100vh - 142px);
	overflow: auto;
}

table.companies-list thead {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: white;
}

table.companies-list td {
	font-size: 18px;
}

table.companies-list th.status,
table.companies-list td.status {
	width: 200px;
	text-align: center;
}

table.companies-list td.task-action {
	width: 200px;
}

table.companies-list td .btn {
	color: #414141;
	padding: 5px 10px;
}

table.companies-list td .btn i {
	font-size: 20px;
}
