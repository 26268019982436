.location-settings form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.location-settings form > div {
    font-size: .8rem;
}

.location-settings form > .buttons-container {
    flex-shrink: 0;
    margin: 0;
}

.locations-generator .clipboard {
    opacity: 0.1;
    height:0;
    width: 0;
    position:absolute;
    top: -10px;
    left: -10px;
    z-index: -1;
}

.locations-generator .results-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}