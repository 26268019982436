:root {
	--bad-color: #ff4a4a;
	--good-color: #bdf2d5;
	--decent-color: #ff9551;
	--excellent-color: #3ccf4e;
}

.grid-keyword-map-stats-container,
.grid-report-map-container-v4 {
	padding: 0 10px;
}

.grid-report-map-container-v4.full-screen {
	padding: 0;
}

.grid-report-map-container-v4 .map-full-screen-wrapper {
	text-align: center;
	font-weight: normal;
}

.grid-report-map-container-v4.full-screen .map-full-screen-wrapper {
	position: absolute;
	bottom: 0;
	text-align: center;
	background-color: white;
}

.grid-report-map-container-v4 .map-full-screen-wrapper .btn {
	color: #333333;
	background-color: #ededed;
	border: 1px solid #d3d3d3;
	font-weight: normal;
	padding: 5px 15px;
	margin: 5px 0;
	text-decoration: none;
}

.grid-report-map-container-v4 .map-wrapper {
	padding-left: 2px;
	padding-right: 4px;
}

.grid-report-map-container-v4.full-screen .map-wrapper {
	height: calc(100% - 45px);
}

.map {
	height: 800px;
}

.map.single-public-view {
	height: calc(100vh - 280px) !important;
}

.map-data-not-available {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #c9c9c9;
	padding: 50px;
}

.map-data-not-available .box {
	background-color: white;
	color: #333333;
	padding: 20px 50px;
	font-size: 20px;
}

.ranking-bubble {
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	border-radius: 8px;
	transition: all .3s ease-out;
	background-color: transparent;
	cursor: pointer;
	animation: rankingBubble .5s ease-out;
	opacity: 1;
	position: relative;
}

.ranking-bubble .details {
	position: absolute;
	bottom: 42px;
	left: 50%;
	transform: translateX(-50%);
	display: none;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: center;
	transition: all .3s ease-out;
	flex: 1;
	padding-left: 10px;
	padding: 10px;
	transform: translateY(-30%);
	opacity: 0;
	border-radius: 8px;
	transition: all .3s ease-out;
	background-color: white;
	min-width: 300px;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.ranking-bubble .details::after {
	content: "";
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translate(-50%, 0);
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid white;
}

.ranking-bubble .details .business-list {
	flex: 1;
}

.ranking-bubble .details .business-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	border-radius: 8px;
	font-size: 14px;
}

.ranking-bubble .details .business-container > * {
	padding: 5px 10px;
	text-align: left;
}

.ranking-bubble .details .business-container .name {
	flex: 1;
	padding: 0;
	padding-right: 20px;
}

.ranking-bubble .details .business-container .ranking,
.ranking-bubble .details .business-container .url {
	min-width: 15px;
	text-align: center;
}

.ranking-bubble .details .business-container .url {
	text-align: right;
}

.ranking-bubble .details .business-container.target {
	background-color: var(--excellent-color);
	color: white;
}

.ranking-bubble .details .business-container.target .url a {
	color: white;
}

.ranking-bubble .icon {
	min-width: 34px;
	min-height: 34px;
	border-radius: 8px;
	background-color: white;
}

.ranking-bubble .icon .value {
	display: block;
	line-height: 34px;
	text-align: center;
	font-size: 18px;
	color: white;
}

.ranking-bubble.bad .icon {
	background-color: var(--bad-color);
}

.ranking-bubble.decent .icon {
	background-color: var(--decent-color);
}

.ranking-bubble.good .icon {
	background-color: var(--good-color);
}

.ranking-bubble.good .icon span {
	color: green;
}

.ranking-bubble.excellent .icon {
	background-color: var(--excellent-color);
}

.ranking-bubble.improvement-good .icon {
	/* border-bottom: 3px solid var(--excellent-color); */
	border-bottom: 3px solid #5a8a43;
}

.ranking-bubble.improvement-bad .icon {
	/* border-bottom: 3px solid var(--bad-color); */
	border-bottom: 0;
}

.ranking-bubble .improvement {
	display: none;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	padding: 0 5px;
}

.ranking-bubble .improvement span {
	display: block;
	font-weight: bold;
}

.ranking-bubble .improvement i {
	margin-right: 10px;
	font-size: 20px;
}

.ranking-bubble .improvement.bad i {
	color: var(--bad-color);
}

.ranking-bubble .improvement.good i {
	color: green;
}

.ranking-bubble.highlight {
	background-color: white;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
	opacity: 1;
}

.ranking-bubble.highlight .improvement {
	display: flex;
}

.ranking-bubble.highlight .details {
	display: flex;
	transform: translateY(0%) translateX(-50%);
	opacity: 1;
}

.ranking-bubble.highlight .details .improvement.bad {
	display: none;
}

.ranking-bubble.not-found {
	border: 1px solid #333;
	background-color: #ddd;
	opacity: 1;
	pointer-events: none;
}

.ranking-bubble.not-found .icon {
	background-color: transparent;
}

.ranking-bubble.not-found .icon .value {
	color: #333;
}

.ranking-bubble.not-found::after {
	display: none;
}

@keyframes rankingBubble {
	0% {
		transform: translateY(-30%);
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

@media screen and (max-width: 767px) {
	.ranking-bubble .icon {
		min-width: 16px;
		min-height: 16px;
		border-radius: 8px;
		background-color: white;
	}

	.ranking-bubble .icon .value {
		display: block;
		line-height: 16px;
		text-align: center;
		font-size: 8px;
		color: white;
	}

	.ranking-bubble::after {
		display: none;
	}

	.grid-report-map-container-v4 .map-full-screen-wrapper {
		display: none;
	}
}
