.new-user-wrapper {
	position: absolute;
	top: -48px;
	right: 0px;
	z-index: 2;
}

table.users-list tr:hover {
	background-color: #ececec;
}

table.users-list th.number,
table.users-list td.number {
	width: 5%;
}

table.users-list th.count,
table.users-list td.count {
	width: 7%;
	text-align: center;
}

table.users-list th.name,
table.users-list td.name {
	width: 500px;
	max-width: 500px;
}

table.users-list th.email,
table.users-list td.email {
	width: auto;
}

table.users-list th.user-action,
table.users-list td.user-action {
	width: 14%;
}
