.ranking-filters .nav-pills {
	gap: 30px;
}

.ranking-filters .nav-pills .nav-item .nav-link {
	color: black;
	cursor: pointer;
	border-radius: 20px;
}

.ranking-filters .nav-pills .nav-item .nav-link.active {
	background-color: black;
	color: white;
	border-radius: 20px;
}
