.business-confirm-container {
	margin: 0 auto;
	max-width: 400px;
	text-align: center;
}

.sales-company-exists-container {
	margin-top: var(--gap);
	font: 20px;
}
