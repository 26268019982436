table.reviews-table {
	table-layout: fixed;
}

table.reviews-table .name {
	width: 20%;
}

table.reviews-table .text {
	width: 70%;
}

table.reviews-table .keyword-action {
	width: 10%;
}

table.reviews-table td .btn {
	color: #414141;
	padding: 5px 10px;
}

table.reviews-table td .btn i {
	font-size: 20px;
}
