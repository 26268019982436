.sales-grid-size-container {
	padding: 20px;
}

.map-preview {
	height: calc(100vh - 215px) !important;
	min-height: 400px;
	border: 1px solid #ddd;
	position: relative;
}

.map-preview .map {
	height: calc(100vh - 215px) !important;
	min-height: 400px;
}
