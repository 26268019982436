table.items-list tfoot.list-footer th {
	padding: 0;
}

table.items-list tfoot.list-footer .items-list-count {
	display: flex;
	align-items: center;
	padding: 5px 8px;
	border-top: 1px solid #dee2e6;
}

table.items-list tfoot.list-footer .btn-refresh-list {
	color: #212529;
	text-decoration: underline;
	margin-left: 25px;
	padding: 0;
}
