.campaign-report {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.campaign-report > * {
	width: 100%;
}

.campaign-report .content {
	flex: 1;
	padding: var(--gap);
}

.campaign-report-input {
	height: 38px;
}

.campaign-report-input.merge-number {
	width: 80px !important;
}

.campaign-report-input.day-of-month {
	width: 80px !important;
}

.campaign-report-input.timezone-select {
	width: 270px;
}

.campaign-report .campaign-v-p-report-v5 .rank-report .rank-table thead {
	position: relative;
	top: 0;
}

.campaign-report .headline-links-wrapper {
	margin-left: 30px;
	margin-top: 10px;
}

.campaign-report .headline-links-wrapper .headline-link {
	font-size: 20px;
	color: #333333;
}
