.login-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.login-wrapper form {
	width: 500px;
}
