.grid-report-keywords {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 30px;
	margin: 30px auto 10px auto;
}

.grid-report-keywords .grid-report-keyword {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 20px;
	background-color: #ddd;
	border: 1px solid #f2f2f2;
	border-radius: 5px;
	cursor: pointer;
}

.grid-report-keywords .grid-report-keyword.active {
	color: var(--primary-color);
	border-color: var(--primary-color);
}
