.campaign-v-p-report-v4 {
	text-align: center;
	padding: 12px;
}

.campaign-v-p-report-v4 .report-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	margin-bottom: 10px;
	background-color: white;
	padding: 10px 0;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 3;
}

.campaign-v-p-report-v4 .report-header .display-5 {
	font-size: 2rem;
	font-weight: 500;
	font-size: 20px;
}

.campaign-v-p-report-v4 .report-header .text-muted {
	font-size: 16px;
}

.campaign-v-p-report-v4 .report-header .report-header-right {
	text-align: right;
}

.campaign-v-p-report-v4 .rank-report .rank-table thead {
	top: 142px;
}

.campaign-v-p-report-v4 .report-body {
	text-align: left;
	margin-top: 10px;
}

.campaign-v-p-report-v4 .report-body .grid-report-keywords {
	top: 106px;
}

.campaign-v-p-report-v4 .report-body .spinner-border {
	display: block;
	margin: 0 auto;
}

.campaign-v-p-report-v4 .ranking-filters {
	margin-top: 30px;
}

.campaign-v-p-report-v4 .campaign-reports {
	position: sticky;
	top: 105px;
	z-index: 3;
	background-color: white;
	margin-bottom: 5px;
	padding: 0;
}

.campaign-v-p-report-v4 .canceled-campaign-warning {
	padding: 40px;
	font-size: 28px;
	font-weight: bold;
	color: #e91717;
}

.campaign-v-p-report-v4 .no-reports-notice {
	padding: 20px;
	font-size: 20px;
}

.campaign-v-p-report-v4 .campaign-reports .flex-fill {
	text-align: left;
}

.campaign-v-p-report-v4 .report-headline-links-wrapper {
	margin-left: 30px;
}

.campaign-v-p-report-v4 .report-headline-links-wrapper .report-headline-link {
	font-size: 20px;
	color: #505050;
}

.campaign-v-p-report-v4 .report-headline-links-wrapper .report-headline-link:last-child {
	padding-right: 0;
}

.report-headline-link-separator {
	font-size: 22px;
	vertical-align: middle;
	padding: 0 5px;
}

.one-line-input-label {
	min-width: 150px;
}

@media screen and (max-width: 767px) {
	.campaign-v-p-report-v4 .report-header {
		flex-direction: column;
		align-items: stretch;
	}

	.campaign-v-p-report-v4 .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report-v4 .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report-v4 .rank-report .rank-table thead {
		top: 159px;
	}
}

@media screen and (max-width: 991px) {
	.campaign-v-p-report-v4 .report-header {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 0;
	}

	.campaign-v-p-report-v4 .report-header .report-header-left {
		overflow: hidden;
	}

	.campaign-v-p-report-v4 .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.campaign-v-p-report-v4 .rank-report .rank-table thead {
		top: 159px;
	}

	.campaign-v-p-report-v4 .campaign-reports {
		top: 125px;
	}

	.campaign-v-p-report-v4 .report-body .grid-report-keywords {
		top: 190px;
	}
}
