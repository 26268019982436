.campaign-form-container .campaign-image {
	display: grid;
	margin-right: 30px;
	position: relative;
}

.campaign-form-container .campaign-image label {
	width: 250px;
}

.campaign-form-container .campaign-image .image-card,
.campaign-form-container .campaign-image img {
	width: 250px;
	border: 1px solid #ddd;
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	padding-bottom: 80%;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	background-size: auto;
	background-repeat: no-repeat;
	background-position: center;
}

/* images */
.images-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	height: calc(100vh - 255px);
	max-height: 400px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: .25rem;
	overflow: auto;
}

.images-list.disabled {
	opacity: 0.6;
}

.image-card {
	height: 0;
	padding-bottom: 56.25%;
	background-size: cover;
	background-position: center;
	position: relative;
	overflow: hidden;
	background-color: #eee;
	text-decoration: none;
	color: var(--bs-body-color);
	border: 1px solid transparent;
	cursor: pointer;
}

.image-preview-change {
	position: absolute;
	bottom: 25px;
	right: 0;
	padding: 2px;
}

.image-preview-change i {
	/* text-shadow: 0px 0px 3px #ffffff; */
}

.image-card:hover {
	background-color: #f0f0f0;
	color: var(--bs-body-color);
}

.image-card.active {
	box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
		rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.image-card .image-name {
	font-size: 13px;
}

.image-card .image-preview {
	font-size: 20px;
	width: 100%;
}

.image-card .image-preview img {
	position: absolute;
	min-width: 100%;
	left: 0%;
	top: 0;
}

.images-list.icons {
	grid-template-columns: repeat(5, 1fr);
}

.icon-card {
	text-decoration: none;
	color: var(--bs-body-color);
	cursor: pointer;
	width: 100%;
}

.icon-card.active {
	box-shadow: 0 0 0 2px rgb(6 24 44 / 40%), 0 4px 6px -1px rgb(6 24 44 / 65%), inset 0 1px 0 hsl(0deg 0% 100% / 8%);
}

.icon-card-wrapper {
	padding: 10px;
	border: 1px solid transparent;
}

.icon-card-wrapper:hover {
	background-color: #f0f0f0;
	color: var(--bs-body-color);
}

.icon-card-wrapper .icon-name {
	font-size: 13px;
}

.icon-card-wrapper .icon-preview {
	font-size: 20px;
}

.icon-card-wrapper .icon-preview .icon-image {
	width: 100%;
}

.icons-buttons-wrapper {
	position: fixed;
	bottom: 0;
	width: calc(30% - 40px);
}

.icons-buttons {
	padding: 20px 0px;
}

.images-list.icons .icon-image {
	width: 120px;
}

.icons-list-view-more {
	display: flex;
	padding-bottom: 10px;
	align-items: center;
	justify-content: center;
}

.image-compressor-notice {
	display: block;
	padding: 6px 20px;
	background-color: #f8e2dd;
	color: #d70000;
	border: 2px solid #ff0000;
	border-radius: 5px;
	text-align: center;
	width: 1090px;
	max-width: 100%;
}
