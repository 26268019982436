.map-settings {
	padding: 20px;
}

.map-settings .sidebar {
	min-width: 20%;
	padding: 10px;
	border-right: 1px solid #f2f2f2;
}

.map-settings .content {
	margin-bottom: 20px;
}

.map-settings-form .generate-seo-report {
	margin-bottom: 0;
}

.map-settings-form .point-distance {
	font-size: 18px;
}

.form-control.required-highlight:focus {
	border-color: #ff003f;
	box-shadow: 0 0 0 0.25rem rgb(213 22 22 / 44%);
}

.slider-input {
	width: 100%;
	-webkit-appearance: none;
	border: 0;
	background-color: transparent;
	padding: 0;
	margin-top: 14px;
}

/* slider */
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	background: #e6e6e6;
	border-radius: 1.3px;
	border: 0;
	margin-top: 3px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
	outline: 0;
}

input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	background: #e6e6e6;
	border-radius: 1.3px;
	border: 0;
}

input[type=range]::-webkit-slider-thumb {
	cursor: pointer;
	margin-top: -4px;
}

input[type=range]::-moz-range-thumb {
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	border: 1px solid #000000;
	height: 36px;
	width: 16px;
	border-radius: 3px;
	background: #ffffff;
	cursor: pointer;
}

/* radio button */
.radio-container {
	display: inline;
	position: relative;
	padding-left: 25px;
	margin-bottom: 10px;
	margin-right: 40px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.radio-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.radio-container .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border: 1px solid #d5d5d5;
	border-radius: 50%;
}

.radio-container:hover input ~ .checkmark {
	background-color: #ccc;
}

.radio-container input:checked ~ .checkmark {
	background-color: #0d6efd;
}

.radio-container .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.radio-container input:checked ~ .checkmark:after {
	display: block;
}

.radio-container .checkmark:after {
	top: 5px;
	left: 5px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.checkbox-container input[type=checkbox] {
	margin-right: 10px;
}

.data-grid-map-container.no-transition .ranking-bubble,
.data-grid-map-container.no-transition .ranking-bubble .details {
	transition: none !important;
	animation: none !important;
}
