.new-offer-wrapper {
	position: relative;
	text-align: end;
	z-index: 2;
}

table.items-list.offers-list thead {
	top: -16px;
}

table.items-list.offers-list tfoot {
	bottom: -16px;
}

.offers-list td.offer-name {
	max-width: 300px;
}

.offers-list td.user-email {
	max-width: 250px;
}

.offers-list td.description {
	max-width: 350px;
}

.offer-type-icon {
	color: #000000;
	font-size: 18px;
	margin-right: 10px;
}
