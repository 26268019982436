.ranking-data-container {
	padding: 0 10px;
	margin-bottom: 10px;
}

.grid-ranking-filters-container {
	padding: 0 10px;
}

.rank-report {
	margin-top: var(--gap);
	text-align: left;
}

.rank-report .map-data-not-available {
	margin-top: 78px;
}

.rank-report .rank-stats {
	margin-top: var(--gap);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: var(--gap);
}

.rank-report .rank-table thead {
	position: sticky;
	top: 58px;
	background-color: white;
	z-index: 1;
}

.rank-report .rank-table {
	vertical-align: middle;
	/* border: 1px solid #dddddd;
	border-radius: 10px; */
}

.rank-report .rank-table .rank-table-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.rank-report .rank-table th,
.rank-report .rank-table td {
	border: 0;
}

.rank-report .rank-table .rank {
	width: 50px;
	text-align: center;
}

.rank-report .rank-table .rank-details {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.rank-report .rank-table .rank-details .rank-difference-value {
	display: inline-block;
	width: 30px;
	text-align: right;
}

.rank-report .rank-table .rank-details .rank-difference.good {
	color: #1ac049;
	font-weight: 600;
}

.rank-report .rank-table .rank-details .rank-difference.bad {
	color: #ff2b2b;
	font-weight: 600;
}

.rank-report .rank-table .actions {
	text-align: right;
	width: 60px;
}

.rank-report .rank-table .actions a {
	margin-right: 10px;
}

.rank-report .rank-table .actions a:last-child {
	margin-right: 0;
}

.rank-report .btn {
	text-align: left;
}

.rank-report .btn.btn-success {
	border-color: #3ccf4e;
	background-color: #cbfce5;
	color: #105b39;
}

.rank-report .btn.btn-warning {
	border-color: #f7b500;
	background-color: #fff7e5;
	color: #5f4b00;
}

.rank-report .btn.btn-danger {
	border-color: #f44336;
	background-color: #ffe5e5;
	color: #5f0000;
}

.rank-report .btn.btn-secondary {
	border-color: #e0e0e0;
	background-color: #f5f5f5;
	color: #333;
}

@media screen and (max-width: 768px) {
	.rank-report .rank-stats {
		flex-direction: column;
		align-items: stretch;
		gap: 10px;
	}

	.rank-report .rank-table {
		font-size: 14px;
	}
}

@media screen and (max-width: 991px) {
	.rank-report {
		margin-top: 10px;
	}

	.rank-report .map-data-not-available {
		margin-top: 0px;
	}
}
