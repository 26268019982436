.search-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 30px;
	margin-bottom: 30px;
}

.search-container > div {
	flex: 1;
}

.search-container .search-button {
	flex: .2;
}

.search-container .search-type-box {
	flex: 0.5 1;
}
