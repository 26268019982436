.requirements-approval-container {
	padding: 20px;
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	color: #444444;
}

.requirements-approval-container .container {
	padding: 0 20px;
}

.requirements-approval-container .requirements-approval-container-inner {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-bottom: 0;
	border-radius: 5px 5px 0 0;
	padding: 40px;
	box-shadow: 0px 0px 7px 0px #dddddd;
}

.requirements-approval-container .requirements-approval-container-inner .title-wrapper {
	padding-bottom: 20px;
}

.requirements-approval-container .requirements-approval-container-inner .title {
	font-weight: 700;
	font-size: 32px;
	color: #0b305b;
}

.requirements-approval-container .requirements-approval-container-inner p {
	font-size: 20px;
	line-height: 1.5em;
}

.requirements-approval-container .requirements-approval-container-inner a {
	color: #2b7bb9;
	text-decoration: none;
}

.requirements-approval-container .requirements-approval-container-inner pre {
	font-family: Poppins, sans-serif;
	color: #444444;
	font-size: 20px;
	line-height: 1.4em;
	text-wrap: wrap;
}

.requirements-approval-container .requirements-approval-container-inner .campaign-info-wrapper {
	background-color: #f6f5f5;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	padding: 20px 30px;
	margin-bottom: 30px;
}

.requirements-approval-container .requirements-approval-container-inner .video-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	border: 2px solid #ffffff;
	box-shadow: 0 0 7px 2px #e2e2e2;
}

.requirements-approval-container .requirements-approval-container-inner .video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.requirements-approval-container .requirements-approval-container-inner .need-changes-notice-wrapper {
	padding: 0 0.75rem;
	margin-bottom: 20px;
}

.requirements-approval-container .requirements-approval-container-inner .need-changes-notice {
	background-color: #fafde9;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 5px;
}

.requirements-approval-container .requirements-approval-container-inner .form-label {
	min-width: 150px;
}

.requirements-approval-container .requirements-approval-container-inner .keyword-number {
	display: inline-block;
	width: 30px;
}

.requirements-approval-container .approve-button-container {
	position: sticky;
	bottom: 0;
}

.requirements-approval-container .approve-button-container .approve-button-container-inner {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 0 0 5px 5px;
	padding: 30px;
	box-shadow: 0px 0px 7px 0px #dddddd;
}

.requirements-approval-container .approve-button-container .btn.report-url-link {
	background-color: #4ab24a;
	border-color: #327a39;
	border: 1px solid #1f5a87;
	border-radius: 4px;
	width: 100%;
	padding: 10px 20px;
	font-size: 25px;
	color: #ffffff;
}

@media screen and (min-width: 992px) {
	.requirements-approval-container .container {
		width: 800px;
	}
}

@media screen and (max-width: 768px) {
	.requirements-approval-container {
		padding: 10px;
		padding-bottom: 80px;
	}

	.requirements-approval-container .container {
		padding: 0;
	}

	.requirements-approval-container .requirements-approval-container-inner {
		padding: 20px;
	}

	.requirements-approval-container .requirements-approval-container-inner .title-wrapper {
		padding-bottom: 30px;
	}
}
